import React, { FC, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router';
import { useWallet } from '@solana/wallet-adapter-react';

import iconWallet from '../../images/wallet-floating-disconnected.svg';
import WalletModal from './WalletModal.lazy';

interface WalletFloatingProps {
  show?: boolean;
  setShow?: Function;
  cl?: Function;
  redirectTo?: string;
}

const WalletFloating: FC<WalletFloatingProps> = (p) => {
  const history = useHistory();
  const [showingConnectModal, setShowConnectModal] = useState<boolean>(false);
  const { wallet, connect, connecting, connected, select, publicKey, disconnect, signAllTransactions, signTransaction } = useWallet();

  const connectWallet = (address: any) => {
    setShowConnectModal(false);
    if (p.redirectTo) {
      history.push(p.redirectTo);
    }
    if (!address) {
      history.push("/home");
    }

    if (p.cl) {
      p.cl(true);
    }
  }

  const walletNotConnected = () => {
    setShowConnectModal(false);
    if (p.cl) {
      p.cl(false);
    }
  }

  useEffect(() => {
    if (p.show) {
      setShowConnectModal(p.show);
    }
  }, [p.show]);
  return (
    <>
      <div className={`floating-wallet ${connected ? 'connected' : ''}`} onClick={() => { /*setShowConnectModal(!showingConnectModal)*/ if (p.setShow) { p.setShow(!p.show || false) } }}>
        <img src={iconWallet} />
      </div>
      <WalletModal isOpen={p.show || false} onRequestClose={walletNotConnected} onWalletConnected={connectWallet}></WalletModal>
    </>
  )
};

export default WalletFloating;
