
import WalletContext from './WalletContext';
import Content from './Content';
import { stakingService } from './services/StakingService';

declare const window: any;
stakingService.initStaking({})
function App(props: any): JSX.Element {
  return (
    <WalletContext>
      <Content />
    </WalletContext>
  );
}

export default App;
