import React, { lazy, Suspense } from "react";

const LazyErrorMessageModal = lazy(() => import("./ErrorMessageModal"));

const ErrorMessageModal = ({
  isOpen,
  onErrorMessageClose,
  variant,
  title,
  text,
}: {
  isOpen: boolean;
  onErrorMessageClose: Function;
  variant: "stake" | "general";
  title?: string;
  text?: string;
}) => (
  <Suspense fallback={null}>
    <LazyErrorMessageModal
      isOpen={isOpen}
      onErrorMessageClose={onErrorMessageClose}
      variant={variant}
      title={title}
      text={text}
    />
  </Suspense>
);

export default ErrorMessageModal;
