import { IDL as idl, EnrexStake } from "./enrex_stake";
// @ts-ignore
import * as anchor from "@project-serum/anchor";
import {
  Account,
  Connection,
  Keypair,
  PublicKey,
  TokenAccountsFilter,
  sendAndConfirmTransaction,
  StakeProgram,
} from "@solana/web3.js";
// @ts-ignore
import { TOKEN_PROGRAM_ID, createMint as createMintLib } from "@solana/spl-token";
import config from "../config";
import { Wallet } from "@project-serum/anchor/dist/cjs/provider";
import Bottleneck from "bottleneck";

const limiter = new Bottleneck({
  maxConcurrent: 1,
  minTime: 1000,
});

let program: anchor.Program<EnrexStake> = null as any;
let programId: anchor.web3.PublicKey = null as any;
let provider: anchor.AnchorProvider = null as any;
let wallet: Wallet = null as any;

const { BN, web3, Program, AnchorProvider } = anchor;

const defaultAccounts = {
  tokenProgram: TOKEN_PROGRAM_ID,
  clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
  systemProgram: anchor.web3.SystemProgram.programId,
  rent: anchor.web3.SYSVAR_RENT_PUBKEY,
};

export const STATE_TAG = Buffer.from("state");
export const STAKE_INFO_TAG = Buffer.from("stake-info");

export interface IResult {
  success: boolean;
  data: any;
  msg: string;
}

export async function getPda(seeds: (Buffer | Uint8Array)[], programId: anchor.web3.PublicKey) {
  const [pdaKey] = await anchor.web3.PublicKey.findProgramAddress(seeds, programId);
  return pdaKey;
}

export const getLamport = (amount: number, decimals: number = config.staking.decimals): anchor.BN => {
  return new BN(amount * 10 ** decimals);
};

export async function createMint(provider: any, authority: anchor.web3.PublicKey, decimals = 9) {
  if (authority === undefined) {
    authority = provider.wallet.publicKey;
  }
  const mint = await createMintLib(
    provider.connection,
    provider.wallet.payer,
    authority,
    null,
    decimals,
    undefined,
    undefined,
    TOKEN_PROGRAM_ID
  );
  return mint;
}

//Initialize program upon connecting to wallet
//You can use functions in the api only after calling this function
//Call this function again whenever you're switching the wallet or connecting your wallet again.
export const initProgram = (connection: anchor.web3.Connection, walletArg: Wallet, pid: PublicKey): IResult => {
  let result: IResult = { success: true, data: null, msg: "" };
  try {
    programId = pid;
    provider = new AnchorProvider(connection, walletArg, AnchorProvider.defaultOptions());

    // Generate the program client from IDL.
    program = new (anchor as any).Program(idl, programId, provider) as anchor.Program<EnrexStake>;

    wallet = walletArg;
    console.log("wallet has changed into", wallet.publicKey.toString());
  } catch (e: any) {
    result.success = false;
    result.msg = e.message;
  } finally {
    return result;
  }
};

export async function getStatePda() {
  const stateKey = await getPda([STATE_TAG], programId);
  return stateKey;
}

export async function getPoolPda(mint: string | PublicKey, pool_index: number) {
  const poolSigner = await getPda([new PublicKey(mint).toBuffer(), Buffer.from([pool_index])], programId);
  return poolSigner;
}

export async function getPoolVault(mint: string | anchor.web3.PublicKey, pool_signer: string | anchor.web3.PublicKey) {
  const poolVault = await getPda([new PublicKey(mint).toBuffer(), new PublicKey(pool_signer).toBuffer()], programId);
  return poolVault;
}

export async function getNewStakeInfoAccountPda(pool_pda: string | PublicKey) {
  let poolInfo = await program.account.farmPoolAccount.fetch(pool_pda);

  const [stakeInfoPda] = await anchor.web3.PublicKey.findProgramAddress(
    [
      STAKE_INFO_TAG,
      new PublicKey(pool_pda).toBuffer(),
      provider.wallet.publicKey.toBuffer(),
      poolInfo.incStakes.toArrayLike(Buffer, "be", 8),
    ],
    program.programId
  );

  console.log("incStakes", poolInfo.incStakes.toNumber());
  console.log("stakedInfo", stakeInfoPda.toString());
  return stakeInfoPda;
}

export async function getStakeInfoAccountPdaByIndex(pool_pda: string | PublicKey, stake_index: number) {
  let poolInfo = await program.account.farmPoolAccount.fetch(pool_pda);

  const [stakeInfoPda] = await anchor.web3.PublicKey.findProgramAddress(
    [
      STAKE_INFO_TAG,
      new PublicKey(pool_pda).toBuffer(),
      provider.wallet.publicKey.toBuffer(),
      new BN(stake_index).toArrayLike(Buffer, "be", 8),
    ],
    program.programId
  );

  console.log("incStakes", poolInfo.incStakes.toNumber());
  console.log("stakedInfo", stakeInfoPda.toString());
  return stakeInfoPda;
}

export async function createState(mint: string | PublicKey) {
  let stateSigner = await getStatePda();

  await program.rpc.createState({
    accounts: {
      state: stateSigner,
      tokenMint: mint,
      authority: provider.wallet.publicKey,
      ...defaultAccounts,
    },
  });
}

export async function createPool(
  apy: number,
  min_stake_amount: number,
  lock_duration: anchor.BN,
  mint: string | PublicKey
) {
  let stateSigner = await getStatePda();

  let pools = await program.account.farmPoolAccount.all();
  let pool_index = pools.length;
  let poolSigner = await getPoolPda(mint, pool_index);

  const poolVault = await getPda([new PublicKey(mint).toBuffer(), poolSigner.toBuffer()], programId);

  await program.rpc.createPool(pool_index, apy, getLamport(min_stake_amount), lock_duration, {
    accounts: {
      pool: poolSigner,
      state: stateSigner,
      vault: poolVault,
      mint: mint,
      authority: provider.wallet.publicKey,
      ...defaultAccounts,
    },
  });
}

export async function fundPool(
  mint: anchor.web3.PublicKey,
  vault: anchor.web3.PublicKey,
  pool_index: number,
  amount: number
) {
  let stateSigner = await getStatePda();
  let poolSigner = await getPoolPda(mint, pool_index);
  let poolVault = await getPoolVault(mint, poolSigner);

  const tx = await program.methods
    .fundPool(getLamport(amount))
    .accounts({
      state: stateSigner,
      pool: poolSigner,
      authority: provider.wallet.publicKey,
      poolVault: poolVault,
      userVault: vault,
      ...defaultAccounts,
    })
    .signers([])
    .rpc();

  const blockhash = await provider.connection.getLatestBlockhash();

  await provider.connection.confirmTransaction({
    blockhash: blockhash.blockhash,
    lastValidBlockHeight: blockhash.lastValidBlockHeight,
    signature: tx,
  });
  return tx;
}

export async function withdraw(
  mint: anchor.web3.PublicKey,
  vault: anchor.web3.PublicKey,
  pool_index: number,
  amount: number
) {
  let stateSigner = await getStatePda();
  let poolSigner = await getPoolPda(mint, pool_index);
  let poolVault = await getPoolVault(mint, poolSigner);

  const tx = await program.methods
    .withdrawPool(getLamport(amount))
    .accounts({
      state: stateSigner,
      pool: poolSigner,
      authority: provider.wallet.publicKey,
      poolVault: poolVault,
      userVault: vault,
      ...defaultAccounts,
    })
    .signers([])
    .rpc();

  const blockhash = await provider.connection.getLatestBlockhash();

  await provider.connection.confirmTransaction({
    blockhash: blockhash.blockhash,
    lastValidBlockHeight: blockhash.lastValidBlockHeight,
    signature: tx,
  });
  return tx;
}

export async function stake(
  mint: anchor.web3.PublicKey,
  user_vault: anchor.web3.PublicKey,
  pool_index: number,
  amount: number
) {
  let stateSigner = await getStatePda();
  let poolSigner = await getPoolPda(mint, pool_index);
  let poolVault = await getPoolVault(mint, poolSigner);
  let stakeInfo = await getNewStakeInfoAccountPda(poolSigner);

  const tx = await program.methods
    .stake(getLamport(amount))
    .accounts({
      stakedInfo: stakeInfo,
      state: stateSigner,
      pool: poolSigner,
      authority: provider.wallet.publicKey,
      poolVault: poolVault,
      userVault: user_vault,
      ...defaultAccounts,
    })
    .signers([])
    .rpc();

  const blockhash = await provider.connection.getLatestBlockhash();

  await provider.connection.confirmTransaction(
    {
      blockhash: blockhash.blockhash,
      lastValidBlockHeight: blockhash.lastValidBlockHeight,
      signature: tx,
    },
    "finalized"
  );
  return tx;

  // tx.feePayer = wallet.publicKey;

  // // const user_provider = new AnchorProvider(provider.connection, provider.wallet, {
  // //   commitment: "confirmed",
  // // });

  // const hash = await provider.sendAndConfirm(tx, [], { commitment: "confirmed" });
  // return hash;
}

export async function claim(
  mint: anchor.web3.PublicKey,
  user_vault: anchor.web3.PublicKey,
  pool_index: number,
  stake_index: number
) {
  //check if user_vault exists and create
  let stateSigner = await getStatePda();
  let poolSigner = await getPoolPda(mint, pool_index);
  let stakeInfo = await getStakeInfoAccountPdaByIndex(poolSigner, stake_index);
  let poolVault = await getPoolVault(mint, poolSigner);

  const tx = await program.methods
    .claimStake()
    .accounts({
      stakedInfo: stakeInfo,
      state: stateSigner,
      pool: poolSigner,
      authority: provider.wallet.publicKey,
      poolVault: poolVault,
      userVault: user_vault,
      ...defaultAccounts,
    })
    .signers([])
    .rpc();

  const blockhash = await provider.connection.getLatestBlockhash();

  await provider.connection.confirmTransaction({
    blockhash: blockhash.blockhash,
    lastValidBlockHeight: blockhash.lastValidBlockHeight,
    signature: tx,
  });
  return tx;
}

export async function cancelStake(
  mint: anchor.web3.PublicKey,
  user_vault: anchor.web3.PublicKey,
  pool_index: number,
  stake_index: number
) {
  let stateSigner = await getStatePda();
  let poolSigner = await getPoolPda(mint, pool_index);
  let stakeInfo = await getStakeInfoAccountPdaByIndex(poolSigner, stake_index);
  let poolVault = await getPoolVault(mint, poolSigner);

  const tx = await program.methods
    .cancelStake()
    .accounts({
      stakedInfo: stakeInfo,
      state: stateSigner,
      pool: poolSigner,
      authority: provider.wallet.publicKey,
      poolVault: poolVault,
      userVault: user_vault,
      ...defaultAccounts,
    })
    .signers([])
    .rpc();

  const blockhash = await provider.connection.getLatestBlockhash();

  await provider.connection.confirmTransaction({
    blockhash: blockhash.blockhash,
    lastValidBlockHeight: blockhash.lastValidBlockHeight,
    signature: tx,
  });
  return tx;
}

export async function getPoolsInner() {
  let pools = await program.account.farmPoolAccount.all();
  return pools;
}

export async function getStakesInner(pool_pda: string | null = null, user_vault: string | null = null) {
  let stakes = await program.account.stakedInfo.all();
  return stakes.filter(function (el) {
    let result = true;
    if (pool_pda !== null) result = result && el.account.pool.toString() == pool_pda;
    if (user_vault !== null) result = result && el.account.authority.toString() == user_vault;
    return result;
  });
}

export const getPools = limiter.wrap(getPoolsInner);
export const getStakes = limiter.wrap(getStakesInner);
