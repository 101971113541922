import { FC } from "react";
import Modal from "react-modal";
import closeIcon from '../../images/icon-close.svg';

interface InfoModalProps {
    isOpen: boolean
    onClose: () => void
    title: string
    buttonText?: string
    onButtonClick?: () => void
    buttonDisabled?: boolean
}

const InfoModal: FC<InfoModalProps> = (props) => {
    return <Modal isOpen={props.isOpen}
        onRequestClose={() => props.onClose()}
        className='modal'
        overlayClassName='modalBackground'>
        <div className="content-container-modal">
            <label className='modalTitle'>{props.title}</label>
            {props.children}
            <button disabled={props.buttonDisabled} className={`modalButton greenHoverBorder noDisabledLock`}
                onClick={() => props.onButtonClick ? props.onButtonClick() : ''}>
                {props.buttonText ? props.buttonText : 'okay'}
            </button>
            <button
                className="close"
                aria-label="Close"
                onClick={() => props.onClose()}>
                <img src={closeIcon} />
            </button>
        </div>
    </Modal>
};

export default InfoModal;